import { BanAction } from './BanAction'

import { ActionNodeActionProxy } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions"
import ChannelsInfoHelper from "piramis-base-components/src/components/Flow/new/logic/Helpers/ChannelsInfoHelper";

export class BanActionProxy extends ActionNodeActionProxy {
    action: BanAction
    errorTargets: Array<any> = []

    constructor(action: BanAction) {
        super()

        action.targets.forEach(t => {
            const target = ChannelsInfoHelper.GetChannel(t!.toString())

            if (!target?.status) {
                this.errorTargets.push(t!.toString())
            }
        })

        this.action = action
    }

    getModel(): BanAction {
        return this.action
    }

    hasTargets() : boolean {
        return this.action.targets.length > 0
    }

    removeTarget(id:number) {
        this.action.targets = this.action.targets.filter(t => t !== id)
    }

    removeErrorTarget(value: string) {
        this.errorTargets = this.errorTargets.filter(target => target !== value)
    }

    setErrorTarget(data:any) {
        this.errorTargets.push(data)
    }

    setToTargets(target: number): void {
        this.action.targets.push(target)
    }

    clearTargets(): void {
        this.action.targets = [];
        this.errorTargets = [];
    }
}