import ActionNodeAction from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions'
import { ActionTypes } from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/Actions/types'
import { ChatTargetInterface } from 'piramis-base-components/src/components/Flow/new/logic/Editor/utils/ChatTargetInterface'

import { Exclude, plainToInstance } from 'class-transformer'
import { Guid } from 'guid-typescript'

export class BanAction extends ActionNodeAction<BanAction> implements ChatTargetInterface {
    declare type: ActionTypes.Ban
    targets!: Array<number>

    @Exclude()
    guid: string = Guid.create().toString()

    getNewModel() {
        return plainToInstance(BanAction, {
            type: ActionTypes.Ban,
            targets: []
        })
    }

    getChats(): Array<number> {
        return this.targets || [];
    }
}
