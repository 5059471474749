





















import { BanActionProxy } from '../../logic/BanActionProxy'

import SampleNodeMixin from 'piramis-base-components/src/components/Flow/new/logic/Nodes/SampleNodeMixin'
import ActionNode
  from 'piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/ActionNode'
import { TargetCardInfo } from "piramis-base-components/src/components/TargetCard/types";
import ChannelsInfoHelper from "piramis-base-components/src/components/Flow/new/logic/Helpers/ChannelsInfoHelper";
import { SuccessChannelInfo } from "piramis-base-components/src/models/CheckChannels/CheckChannels";
import { Component, Mixins, Prop } from 'vue-property-decorator'
import TargetCard from "piramis-base-components/src/components/TargetCard/TargetCard.vue";

@Component({
  components: {
    TargetCard
  }
})
export default class BanActionNode extends Mixins(SampleNodeMixin) {
  @Prop() action!: BanActionProxy

  @Prop() node!: ActionNode

  get beautifiedTargets(): Array<TargetCardInfo> {
    return this.action.action.targets.map(id => {
      if (id < 0) {
        const target = ChannelsInfoHelper.GetChannel(id.toString())

        if (target) {
          const isSuccessChannelInfo = target instanceof SuccessChannelInfo

          return {
            type: isSuccessChannelInfo ? target?.info?.type?.toLowerCase()?.toString() : null,
            id: isSuccessChannelInfo ? target?.info?.id : target!.value,
            title: isSuccessChannelInfo ? target?.info?.title : null,
            error: null
          }
        }
      }

      return {
        type: null,
        id: id,
        title: null,
        error: null
      }
    })
  }

}
