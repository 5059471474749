








































































































import {
  ChannelInfo,
  ErrorChannelInfo,
  SuccessChannelInfo
} from 'piramis-base-components/src/models/CheckChannels/CheckChannels'

import { BanActionProxy } from '../../logic/BanActionProxy'

import { Component, Mixins, Prop } from 'vue-property-decorator'
import CustomEditor from "piramis-base-components/src/components/Flow/new/logic/Editor/CustomEditor";
import { ActionNodeProps } from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/logic/types";
import ChannelsInfoHelper from "piramis-base-components/src/components/Flow/new/logic/Helpers/ChannelsInfoHelper";
import { plainToInstance } from "class-transformer";
import NotifyGroupActionSetChannelField
  from "piramis-base-components/src/components/Flow/new/logic/Nodes/Communications/Action/ui/sidebar/Actions/NotifyGroupAction/components/NotifyGroupActionSetChannelField.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import ModelSetter from "piramis-base-components/src/Mixins/ModelSetter";
import SelectInput from 'piramis-base-components/src/components/Pi/fields/SelectInput/SelectInput.vue'

enum BanType { Bot, Targets }

@Component({
  components: {
    NotifyGroupActionSetChannelField,
    SelectInput
  },
  data() {
    return {
      SuccessChannelInfo,
      ErrorChannelInfo,
      BanType
    }
  }
})
export default class BanActionSidebar extends Mixins(ModelSetter) {
  @Prop() action!: BanActionProxy

  @Prop() targets!: ActionNodeProps['targets']

  @Prop() editor!: CustomEditor

  isLoading = false

  selected = this.action.hasTargets() ? BanType.Targets : BanType.Bot

  get fieldTypeOptions():Array<SelectOption> {
    return [
      { label: this.$t('flow_action_sidebar_ban_action_type_bot'), value: BanType.Bot },
      { label: this.$t('flow_action_sidebar_ban_action_type_targets'), value: BanType.Targets },
    ]
  }

  checkChannelHandler(channelData: string | number): void {
    this.isLoading = true

    this.editor.getChannelsInfo([ channelData ])
      .then(({ data }) => {
        const channelInfo = data[0]

        if (channelInfo instanceof SuccessChannelInfo) {
          this.action.setToTargets(channelInfo.info.id)
          this.action.removeErrorTarget(channelData.toString())
        }
      })
      .finally(() => {
        this.isLoading = false
      })
  }

  get tableTargets() {
    return [
      ...this.action.action.targets,
      ...this.action.errorTargets
    ]
      .filter(id => !!id)
      .map(id => {
        const existedTarget = this.$store.getters.botTargets.find(t => t.id === id)

        if (existedTarget) {
          return plainToInstance(SuccessChannelInfo, {
            status: true,
            info: {
              id: existedTarget.id,
              login: existedTarget.login,
              title: existedTarget.title,
            }
          })
        }

        const channel = ChannelsInfoHelper.GetChannel(id!.toString())

        if (!channel?.status) {
          return plainToInstance(ErrorChannelInfo, channel)
        } else {
          return plainToInstance(SuccessChannelInfo, channel)
        }
      })
      .filter(target => !!target)
  }

  removeTarget(target: ChannelInfo): void {
    if (target instanceof SuccessChannelInfo) {
      this.action.removeTarget(target.info.id)
    }

    if (target instanceof ErrorChannelInfo) {
      this.action.removeErrorTarget(target.value)
    }
  }

  onChange(event: SelectOption) {
    if (event.value == BanType.Bot) this.action.clearTargets()
  }
}
