var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ban-action-sidebar"},[_c('select-input',{staticClass:"form-field-select",attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        'model': this,
        'key': 'selected',
        'options': _vm.fieldTypeOptions,
        'clearable': false,
        'titleVisible': false
      }
    }},on:{"input":_vm.onChange}}),(_vm.selected == _vm.BanType.Targets)?[(_vm.tableTargets.length)?_c('a-table',{staticClass:"mb-2",attrs:{"data-source":_vm.tableTargets,"row-key":function (record, index) { return (index + "-row"); },"row-class-name":function (record) { return record.status ? 'success-check' : 'false-check'; },"pagination":false,"locale":{
        emptyText: _vm.$t('list_empty').toString()
      }}},[_c('a-table-column',{key:"text",attrs:{"title":_vm.$t('flow_subscription_check_channel_info_title').toString()},scopedSlots:_vm._u([{key:"default",fn:function(record){return _c('div',{staticClass:"flex flex-col"},[(record instanceof _vm.SuccessChannelInfo)?_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"block"},[_vm._v(" "+_vm._s(record.info.title)+" ")]),(record.info.login)?_c('small',[_vm._v(" (@"+_vm._s(record.info.login)+") ")]):_vm._e()])]):_vm._e(),(record instanceof _vm.ErrorChannelInfo)?_c('div',[_c('p',{staticClass:"mb-0"},[_c('span',{staticClass:"block"},[_vm._v(" "+_vm._s(record.value)+" ")]),_c('small',[_vm._v(" "+_vm._s(record.error)+" ")])])]):_vm._e()])}}],null,false,3883689479)}),_c('a-table-column',{key:"action",attrs:{"title":" "},scopedSlots:_vm._u([{key:"default",fn:function(record){return _c('div',{staticClass:"flex justify-end"},[(record instanceof _vm.ErrorChannelInfo)?_c('a-button',{staticClass:"mr-2",attrs:{"size":"small","icon":"redo","type":"danger"},on:{"click":function($event){return _vm.checkChannelHandler(record.value)}}}):_vm._e(),_c('a-button',{attrs:{"size":"small","icon":"delete","type":"danger"},on:{"click":function($event){return _vm.removeTarget(record)}}})],1)}}],null,false,1745742964)})],1):_vm._e(),_c('a-spin',{attrs:{"spinning":_vm.isLoading}},[_c('notify-group-action-set-channel-field',{staticClass:"my-2",attrs:{"prefix":"kick_action_","editor":_vm.editor,"target-options":function () { return _vm.$store.getters.boardTargetOptions; }},on:{"set-channel":function (id) { return _vm.action.setToTargets(id); },"set-error-channel":function (value) { return _vm.action.setErrorTarget(value); }}})],1)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }